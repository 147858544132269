import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { ResourceTypes } from '@/react/data/subsidy/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { Button, Grid2 as Grid, Typography } from '@mui/material';
import StepActions from '@/react/components/wizard/step-actions.component';
import CustomIcon from '@/react/components/custom-icon/custom-icon.component';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { externalResourceTypes } from '@/react/components/refund/utils/external-resource-types';

const Styled = {
  Button: styled(Button)(({ theme: { palette, spacing } }) => ({
    alignItems: 'center',
    backgroundColor: palette.neutral[50],
    border: `1px solid ${palette.neutral[300]}`,
    borderRadius: 0,
    color: palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    padding: spacing(3),
    height: '120px',
    width: '100%',

    '&.active': {
      backgroundColor: palette.primary[100],
      border: `1px solid ${palette.primary[900]}`,
      color: palette.neutral[900],
    },

    '&:disabled': {
      backgroundColor: palette.neutral[100],
      borderColor: palette.neutral[300],
      color: palette.neutral[400],

      '& img': {
        filter: 'grayscale(100%) brightness(0.7)',
        opacity: 0.3,
      },
    },
  })),
};

const stepId = WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM;

export const ExternalResourceTypeForm = () => {
  const { subsidyPolicy } = useRefundRequest();
  const { actions, values: wizardValues } = useWizard();
  const [selected, setSelected] = useState<ResourceTypes>(wizardValues[stepId]);

  const handleSelection = (source: ResourceTypes) => {
    setSelected(source);
  };

  const allowedTypes = useMemo(() => {
    const eligibleTypes = new Set(Object.values(ResourceTypes));
    const eligibleExternalResourceKinds =
      subsidyPolicy?.configuration?.eligible_external_resource_kinds ?? [];

    return eligibleExternalResourceKinds.filter(
      eligibleTypes.has,
      eligibleTypes
    );
  }, [subsidyPolicy]);

  const handleNext = () => {
    actions.updateValues({
      [stepId]: selected,
      [WizardStepIds.RESOURCE_TYPE]: selected,
    });

    let destination: WizardStepIds;
    if (selected === ResourceTypes.COURSE) {
      destination = WizardStepIds.EXTERNAL_COURSE_FORM;
    } else if (selected === ResourceTypes.EVENT_OR_LECTURE) {
      destination = WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM;
    } else if (selected === ResourceTypes.BOOK) {
      destination = WizardStepIds.EXTERNAL_BOOK_FORM;
    } else if (selected === ResourceTypes.OTHERS) {
      destination = WizardStepIds.EXTERNAL_OTHER_FORM;
    }

    actions.navigate(destination);
  };

  const cssClass = (source: ResourceTypes) =>
    selected === source ? 'active' : '';

  return (
    <div data-testid={WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM}>
      <Typography variant="h6" color="primary.900" mb={2}>
        Estou pedindo reembolso para:
      </Typography>
      <Grid container spacing={2}>
        {externalResourceTypes.map(({ type, icon, label }) => (
          <Grid size={{ xs: 12, md: 3 }} key={type}>
            <Styled.Button
              className={cssClass(type)}
              onClick={() => handleSelection(type)}
              disabled={!allowedTypes.includes(type)}
            >
              <CustomIcon svg={icon} alt={`ícone ${label.toLowerCase()}`} />
              <Typography variant="body3" fontWeight={600}>
                {label}
              </Typography>
            </Styled.Button>
          </Grid>
        ))}
      </Grid>
      <StepActions>
        <Button
          color="primary"
          onClick={() => {
            actions.navigate(WizardStepIds.ITEM_SOURCE_FORM);
          }}
          variant="text"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          disabled={!selected}
          onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Próximo
        </Button>
      </StepActions>
    </div>
  );
};

export default ExternalResourceTypeForm;
