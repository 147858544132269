import { ResourceTypes } from '@/react/data/subsidy/interfaces';
import BookIconSvg from '@/react/assets/images/book-icon.svg';
import CourseIconSvg from '@/react/assets/images/course-icon.svg';
import EventsAndLecturesIconSvg from '@/react/assets/images/events-and-lectures-icon.svg';
import OthersIconSvg from '@/react/assets/images/others-icon.svg';

export const externalResourceTypes: {
  type: ResourceTypes;
  icon: string;
  label: string;
}[] = [
  {
    type: ResourceTypes.COURSE,
    icon: CourseIconSvg,
    label: 'Cursos',
  },
  {
    type: ResourceTypes.BOOK,
    icon: BookIconSvg,
    label: 'Livros',
  },
  {
    type: ResourceTypes.EVENT_OR_LECTURE,
    icon: EventsAndLecturesIconSvg,
    label: 'Eventos e Palestras',
  },
  {
    type: ResourceTypes.OTHERS,
    icon: OthersIconSvg,
    label: 'Outros',
  },
];
