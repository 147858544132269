import React from 'react';
import { Box, Grid2 as Grid, Link, Typography } from '@mui/material';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatDate } from '@/react/utils/date';
import {
  RefundResourceStatus,
  ResourceTypes,
} from '@/react/data/subsidy/interfaces';
import theme from '@/react/@mui/theme';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import { shouldShowEditButton } from '../utils';

const ResourceApprovalHeaderTexts = {
  approved: 'Pedido aprovado em: ',
  rejected: 'Pedido recusado em: ',
  canceled: 'Pedido cancelado em: ',
  DEFAULT: 'Pedido aprovado em: ',
} as const;

const getRefundResourceStatusKeyByValue = (
  value: string
): keyof typeof RefundResourceStatus => {
  const foundKey = (
    Object.keys(RefundResourceStatus) as (keyof typeof RefundResourceStatus)[]
  ).find((key) => RefundResourceStatus[key] === value);

  if (!foundKey) {
    return 'DEFAULT' as keyof typeof RefundResourceStatus;
  }

  return foundKey;
};

export const BriefSummary = () => {
  const { resourceApproval } = useRefundRequest();
  const accordion = useAccordion();
  const isExpanded = accordion.steps.resourceDescription.expanded;

  const showEditButton = shouldShowEditButton({ resourceApproval, isExpanded });

  const getSummaryText = (
    resourceType: string,
    name: string,
    institutionName?: string
  ) => {
    const categoryMap = {
      [ResourceTypes.COURSE]: 'Curso',
      [ResourceTypes.BOOK]: 'Livro',
      [ResourceTypes.EVENT_OR_LECTURE]: 'Evento ou Palestra',
      [ResourceTypes.OTHERS]: 'Outros',
    };

    return resourceType === ResourceTypes.COURSE
      ? `${categoryMap[resourceType]} | ${name.split(', ')[0]} | ${institutionName}`
      : `${categoryMap[resourceType]} | ${name}`;
  };

  return (
    <Grid container>
      <Grid
        justifyContent="center"
        sx={({ palette, spacing }) => ({
          color: palette.neutral[800],
          paddingBottom: spacing('0.5'),
          paddingTop: spacing('0.5'),
        })}
      >
        <Typography variant="body3">
          {getSummaryText(
            resourceApproval?.type.label,
            resourceApproval?.name,
            resourceApproval?.resource.course?.institution_name
          )}
        </Typography>
        <Typography variant="body3">
          {resourceApproval?.decided_at &&
            `${ResourceApprovalHeaderTexts[getRefundResourceStatusKeyByValue(resourceApproval.status)]}
             ${formatDate(resourceApproval?.decided_at)}`}
        </Typography>
        {showEditButton && (
          <Box mt={3}>
            <Link
              color={theme.palette.primary[200]}
              fontWeight={600}
              href={`/meus-reembolsos/solicitacao/${resourceApproval.uuid}/editar-solicitacao`}
              onClick={(event) => event.stopPropagation()}
            >
              Editar
            </Link>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
