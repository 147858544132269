import * as React from 'react';
import Stack from '@mui/material/Stack';

const StepActions = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent={{ xs: 'space-between', sm: 'right' }}
        spacing={2}
        marginTop={3}
        sx={{
          flexWrap: { xs: 'wrap-reverse', md: 'nowrap' },
          '& .MuiButton-root': {
            width: '100%',
            maxWidth: { md: '256px' },
            minWidth: '148px',
          },
        }}
      >
        {children}
      </Stack>
    </>
  );
};

export default StepActions;
