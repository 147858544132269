import React from 'react';
import { Box, Button, Typography, Stack, Grid } from '@mui/material';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';
import { ActionByStatusProps } from '@/react/components/refund/refund-list/interfaces';
import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';
import { statusMessages } from '@/react/components/refund/utils/status-messages';
import { StatusChip } from '@/react/components/refund/utils/status-chip';
import { refundableAmount } from '@/react/components/refund/utils/refundable-amount';

const getActionLink = (
  statusApproval: string,
  refundRequestUuid: string,
  resourceApprovalUuid: string
): string => {
  const base = `/meus-reembolsos/solicitacao/${resourceApprovalUuid}`;

  const isShowStepThree =
    statusApproval === 'waitingForRevisionStepTwo' && refundRequestUuid;

  const pathDescriptionStepThree = isShowStepThree
    ? `pedido/${refundRequestUuid}/descricao`
    : 'descricao';

  const statusLinks: Record<string, string> = {
    canceledResourceApprovalStepOne: `${base}/descricao`,
    waitingForRevisionStepTwo: `${base}/${pathDescriptionStepThree}`,
    declinedResourceApprovalStepTwo: `${base}/descricao`,
    canceledRefundRequestStepThree: `${base}/pedido/${refundRequestUuid}/descricao`,
    pendingDocumentationStepThree: `${base}/comprovante`,
    waitingForDocumentationRevisionStepFour: `${base}/pedido/${refundRequestUuid}/comprovante`,
    declinedRefundRequestStepFour: `${base}/pedido/${refundRequestUuid}/comprovante`,
    needsRefundRequestRevisionStepFour: `${base}/pedido/${refundRequestUuid}/validacao`,
    pendingRefundPaymentStepFive: `${base}/pedido/${refundRequestUuid}/pagamento`,
    paidRefundRequestStepFive: `${base}/pedido/${refundRequestUuid}/pagamento`,
  };

  return statusLinks[statusApproval] || base;
};

const ActionByStatus: React.FC<ActionByStatusProps> = ({
  statusApproval,
  refundRequestUuid,
  resourceApprovalUuid,
}) => {
  const { text, type } = statusMessages[statusApproval]?.action || {
    text: 'Ver detalhes',
    type: 'link',
  };

  const actionLink = getActionLink(
    statusApproval,
    refundRequestUuid,
    resourceApprovalUuid
  );

  return (
    <Button
      href={actionLink}
      variant={type === 'button' ? 'contained' : 'outlined'}
      sx={{ borderRadius: 0 }}
    >
      {text}
    </Button>
  );
};

export const RefundCard = ({
  resourceApproval,
}: {
  resourceApproval: ResourceApproval;
}) => {
  const dataFormatted = {
    ...resourceApproval,
    requested_at: new Date(resourceApproval.requested_at).toLocaleDateString(
      'pt-BR'
    ),
    amount: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(refundableAmount(resourceApproval))),
  };

  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
      <Box
        height={'100%'}
        width={'100%'}
        display="flex"
        alignItems="center"
        p={2}
        my={2}
        sx={({ palette }) => ({
          minHeight: 156,
          border: `1px solid ${palette.neutral[300]}`,
          backgroundColor: `${palette.neutral[50]}`,
        })}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {resourceApproval.category}
            </Typography>
            <Typography
              color="primary.200"
              variant={'subtitle2'}
              fontWeight={500}
            >
              {resourceApproval.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" fontWeight={600}>
                  Data da solicitação
                </Typography>
                <Typography variant="body2">
                  {dataFormatted.requested_at}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body2" fontWeight={600}>
                  Reembolso solicitado
                </Typography>
                {dataFormatted.amount && (
                  <Typography variant="body2">
                    {dataFormatted.amount}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body2" fontWeight={600}>
                  Situação
                </Typography>
                <StatusChip statusApproval={mappedStatus} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    justifyContent: 'flex-start',
                    '@media (min-width: 900px)': {
                      justifyContent: 'flex-end',
                    },
                  }}
                >
                  <ActionByStatus
                    statusApproval={mappedStatus}
                    resourceApprovalUuid={resourceApproval.uuid}
                    refundRequestUuid={resourceApproval.refund_request?.uuid}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
